import axiosApi from './axiosApi'

export const getFilter = () => {
    return axiosApi({
        method: 'get',
        url: '/intserv/knowledge/getFilter',
    })
}

export const getAllByTitle = (data) => {
    return axiosApi({
        method: 'post',
        url: '/intserv/knowledge/getAllByTitle',
        data
    })
}

export const getKnowledge = (data) => {
    return axiosApi({
        method: 'get',
        url: `/intserv/knowledge/getKnowledge/${data}`,
    })
}

export const query = (data) => {
    return axiosApi({
        method: 'post',
        url: `/intserv/knowledge/query`,
        data
    })
}
