<template>
  <div class="t-page">
    <img src="https://resources.holowits.com/banner/bannerknowledge.png" alt="" style="width: 100%;">
    <div class="t-body">
        <div class="filter">
            <div class="t-filter-row" v-for="item in types" :key="item">
                <div class="t-filter-row-label"><span>{{ item }}</span></div>
                <div class="t-filter-row-items">
                    <div class="t-filter-row-item">
                        <div class="t-filter-row-item-content" :class="{'active':selectedData === 0}" @click="selected(0)">
                            <span>All</span>
                        </div>
                    </div>
                    <div class="t-filter-row-item" v-for="ele in filters[item]" :key="ele.id">
                        <div class="t-filter-row-item-content" :class="{'active':selectedData === ele.id}" @click="selected(ele.id)">
                            <span>{{ ele.filter }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="search">
                <div class="count">Results Found <span>{{ count }}</span></div>
                <div class="search-bar">
                    <input type="text" v-model="title" placeholder="Enter Keyword"/>
                    <div class="search-btn" @click="getAllByTitle"><i class="el-icon-search"></i></div>
                </div>
            </div>
        </div>

        <div class="csp-list">
            <div class="csp-item" v-for="(item, index) in knowledgeList" :key="index">
                <img @click="toPage(item.id)" :src="item.coverSrc" alt=""/>
                <div class="item-info">
                    <p class="title">{{ item.title }}</p>
                    <div class="pro">
                    <span>{{ item.publishDate | formatDate}}</span> 
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {getFilter, getAllByTitle, getKnowledge} from '@/api/knowledgeApi'
export default {
    name: 'KnowledgeIndex',
    data() {
        return {
            types: [],
            filters: {},
            selectedData: '',
            count: 0,
            title: '',
            knowledgeList: []
        }
    },
    async mounted() {
        await this.getFilter()
        await this.getAllByTitle()
    },
    watch: {
        selectedData() {
            this.getKnowledge()
        }
    },
    methods: {
        async getKnowledge() {
            const {data: {data, isSuccess}} = await getKnowledge(this.selectedData)
            if (isSuccess) {
                this.knowledgeList = data;
                this.count = data.length;
            }
        },
        async getAllByTitle() {
            const {data: {data, isSuccess}} = await getAllByTitle({
                title: this.title
            });
            if (isSuccess) {
                this.knowledgeList = data;
                this.count = data.length;
            }
        },
        toPage(id) {
            // 判断是否登录了账号
            // const account = this.$store.state.client.user.account;
            // if (!account) {
            //     this.$router.push({
            //         path: `/login`
            //     })
            //     return false;
            // }
            // 将获取到的id拼装到url后跳转到对应的播放页面
            this.$router.push({
                path: `/knowledge/${id}/detail`
            })
        },
        selected(val) {
            this.selectedData = val;
        },
        async getFilter() {
            const {data: {data, isSuccess}} = await getFilter();
            if (isSuccess) {
                this.types = [...new Set(data.map(item => item.type))];
                data.forEach(element => {
                    (this.filters[element.type] || (this.filters[element.type] = [])).push(element);
                });
            }
        }
    },
    filters: {
        formatDate(date) {
            if (date) {
                return (new Date(date)).Format('yyyy-MM-dd')
            }
            return date;
        }
    }
}
</script>

<style lang="less" scoped>
.t-page {
  min-height: inherit;
  background-color: #f0f0f0;
//   padding-top: 20px;
  .t-body {
    width: 70%;
    margin: auto;
    padding-top: 20px;
  }
}
.filter {
    width: 100%;
    padding: 0 15px;
    background-color: #fff;
    .t-filter-row {
        min-height: 30px;
        padding: 10px 0;
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid hsla(0,0%,50.2%,.192);
        .t-filter-row-label {
            width: 120px;
            padding: 5px 7px;
            flex-shrink: 0;
            span {
                font-size: 16px;
                font-weight: 600;
            }
        }
        .t-filter-row-items {
            // display: flex;
            // flex-wrap: wrap;
            .t-filter-row-item {
                padding-right: 20px;
                margin-bottom: 5px;
                display: inline-block;
                .t-filter-row-item-content {
                    padding: 4px 6px;
                    border: 1px solid transparent;
                    cursor: pointer;
                    &.active {
                        color: rgb(199, 0, 11);
                        border: 1px solid rgb(199, 0, 11);
                    }
                    &:hover {
                        color: rgb(199, 0, 11);
                        border: 1px solid rgb(199, 0, 11);
                    }
                }
            }
        }
    }
    .search {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .count {
        margin-right: 30px;
        span {
          color: rgba(199,0,11,0.76);
        }
      }
      .search-bar {
        display: flex;
        height: 30px;
        input {
          outline: 0;
          width: 204px;
          height: 30px;
          padding: 2px 10px;
          border: 1px solid #CCCCCC;
          border-radius: 2px 0 0 2px;
          border-right: none;
        }
        .search-btn {
          height: 30px;
          width: 30px;
          text-align: center;
          line-height: 30px;
          background-color: rgba(199,0,11,0.76);
          cursor: pointer;
          i {
            height: 30px;
            width: 30px;
            line-height: 30px;
            color: #ffffff;
            font-size: 18px;
          }
        }
      }
    }
}
.csp-list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    
    .csp-item {
      background-color: #fff;
      width: calc(100% / 4 - 12px);
      display: inline-block;
      margin-bottom: 20px;
      &:not(:nth-child(4n)) {
        margin-right: 16px;
      }
      img {
        width: 100%;
        height: 155.41px;
        cursor: pointer;
      }
      .item-info {
        padding: 0 10px;
        padding-bottom: 10px;
        .title {
          padding: 5px 0;
          font-weight: bold;
          /* 设置文本不换行 */
          // white-space: nowrap;
          /* 设置多余文本以省略号的形式出现 */
          // text-overflow: ellipsis;
          // overflow: hidden;
        }
        .descript {
          // padding-bottom: 10px;
          font-size: 12px;
          color: #a7a7a7;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
          height: 34.28px;
        }
        .pro{
          color: #a7a7a7;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
</style>